import parametres from "@content/advanced/images/calcvariable-settings.png";
import selection from "@content/advanced/images/calcvariable-select.png";

import { PageLayout, Section, Note } from "@components";

import "@styles/Page.scss";

export default function FeatCalcVariables() {
  return (
    <PageLayout title="Calculated variables" roles={["admin"]}>
      <p>
        Calculated variables are special{" "}
        <a href="/en/advanced/variables">
          <b>variables</b>
        </a>{" "}
        that allow you to compute a result based on the data of other existing
        variables in the same form.
      </p>
      <p>
        Example:{" "}
        <em>
          Your form includes the variables "patient height" and "patient
          weight." It is then possible to include the variable "BMI," which will
          be automatically calculated from the two aforementioned data points.
        </em>
      </p>
      <Section id="creating-the-variable" title="Creating the Variable">
        <Note type="note">
          You must first create one or more simple variable(s) to define a
          calculated variable.
        </Note>
        <p>
          Start by creating a variable of type "<b>Calculation</b>" (see{" "}
          <a href="/en/advanced/variables">
            <b>simple variables</b>
          </a>
          ). Fill in the fields in the tab, then go to the{" "}
          <b>Advanced Settings</b> tab.
        </p>
        <p>
          Click on the sub-menu <b>Calculation Configuration</b> to access all
          the operations that the calculated variable will perform.
        </p>
        <img
          src={parametres}
          alt="Configuration of a Calculated Variable"
          className="centered"
        />
      </Section>
      <Section
        id="defining-the-calculated-variable-operation"
        title="Defining the Calculated Variable Operation"
      >
        <p>
          A calculated variable must be defined by a function: that is, a
          mathematical operation that it will apply to the selected input data.
          These input data can be simple variables but also constants.
        </p>
        <div className="responsive-table">
          <table>
            <tr>
              <td>Type of Function</td>
              <td>Usage</td>
            </tr>
            <tr>
              <td>Average</td>
              <td>
                Calculates the average of the selected variables (click on "
                <b>Add an Item</b>" to include more variables). An option allows
                the calculation to proceed even if not all fields are filled in
                by the user.
              </td>
            </tr>
            <tr>
              <td>BMI</td>
              <td>
                Calculates a BMI (Body Mass Index) from the selected "weight"
                and "height" variables.
              </td>
            </tr>
            <tr>
              <td>Diff</td>
              <td>Calculates the difference between two input values.</td>
            </tr>
            <tr>
              <td>DiffDate</td>
              <td>Calculates the difference between two date-type entries.</td>
            </tr>
            <tr>
              <td>Ratio</td>
              <td>
                Calculates the ratio of incoming data relative to a denominator.
                You can add an offset to the result.
              </td>
            </tr>
            <tr>
              <td>Rescaling</td>
              <td>
                Allows for the renormalization of a variable based on an input
                range and an output range.
              </td>
            </tr>
            <tr>
              <td>Sum</td>
              <td>
                Calculates the sum of several input data points. An option
                allows the calculation to proceed even if not all fields are
                filled in by the user.
              </td>
            </tr>
          </table>
        </div>
        <p>
          To select input data, simply click on the field "
          <em>No variable selected</em>," and a window will open listing all the
          study variables. Click on the one you wish to use as input data.
        </p>
        <img src={selection} alt="Select an Input Data" className="centered" />
        <Note type="tip">
          The operation performed is displayed as an equation at the bottom of
          the window to facilitate understanding of the calculation.
        </Note>
      </Section>
    </PageLayout>
  );
}
